<!--
 * @Descripttion: 保全
 * @version: 1.0
 * @Author: 魏鑫
 * @Date: 2019-12-24 14:38:31
 * @LastEditors: 霍铁樱
 * @LastEditTime: 2020-12-28 15:55:45
 -->
<template>
    <div>
      <div class="mainbody" v-if="!isPopupShow">
      <div class="search clearfix">
         <div class="searchlf">
           <md-field>
            <md-input-item
                ref="input"
                v-model="Tbname"
                placeholder="请输入投保人/被保人姓名"
                is-amount
                :maxlength="10"
              ></md-input-item>
          </md-field>
         </div>
         <div class="searchfr">
           <md-field>
              <md-button icon="search" @click="search"></md-button>
           </md-field>
         </div>
         <div class="searchfr">
           <md-field>
              <md-button icon="refresh" @click="cleardata"></md-button>
           </md-field>
         </div>
      </div>
      <div class="list" v-if="reportList.length">
         <md-scroll-view ref="scrollView"
                        auto-reflow
                        :scrolling-x="false"
                        @end-reached="loadMore"
                        :loading-text="totalpage < pageNo || totalpage == pageNo?'没有要加载的数据啦...':'正在加载中'">
          <div class="product" v-for="(item,index) of reportList" :key="index" @click="bqShow(item.pcnum,item.ywtype)" >
            <div class="product-lf">
              <div class="product-rg-top">
                 <img src="@/assets/hx/image/head.png" alt=""  class="tb-head"/>
                  <span class="tb-name">{{item.squsername}}</span>
                  <span class="bq-state">{{item.stateInfo.label}}</span>
                  <span class="bq-ywlabel">{{item.policyflag === '0' ? '不变化': item.policyflag === '1' ? '保费豁免' : '责任终止'}}</span>
              </div>
              <div class="product-bom">
                  <div class="product-rg-bom">
                      <span class="xm-name">理赔项目：</span>
                      <span class="xm-value">{{item.xmname}}</span>
                  </div>
                  <div class="product-rg-bom">
                    <span class="km-rq">保单号：</span>
                    <span class="mq-money">{{item.policyno}}</span>
                  </div>
                  <div class="product-rg-bom">
                      <span class="p-name">险种名称：</span>
                      <span class="p-type">{{item.classname}}</span>
                  </div>
                  <div class="product-rg-bom">
                    <span class="sx-rq">申请时间：</span>
                    <span class="lq-rq">{{item.sqdate}}</span>
                  </div>
              </div>
            </div>
        </div>
          <div class="heightbom"></div>
                    <md-scroll-view-more slot="more" :is-finished="loading" />
        </md-scroll-view>
      </div>
            <md-result-page :img-url="require('@/assets/abd/image/null.png')"
                      v-else
                      subtext="要不然刷新试试？" />
    </div>
    <md-popup v-model="isPopupShow" :mask-closable="false">
          <div class="icdoling">
              <md-icon name="spinner" color="blue" size="lg" style="-webkit-filter:invert(1)"></md-icon>
          </div>
        </md-popup>
    </div>
</template>
<script>
import { bqList } from '@/api/hx/preservation/index'
import loadMore from '@/mixins/loadmore'
export default {
  mixins: [loadMore],
  data () {
    return {
      isPopupShow: true,
      Tbname: '',
      pageNo: 1,
      loading: false,
      reportList: [{ stateInfo: {}, ywcodeInfo: {} }],
      totalpage: 0,
      isSelectorShow: false
    }
  },
  created () {
    this.height = document.body.clientHeight
    this.getData()
  },
  mounted () {
    document.querySelector('.list').style.height = `${this.height}px`
    document.querySelector('.list').style.width = `${this.width}px`
  },
  methods: {
    async getData (isInit = true) {
      this.isPopupShow = true
      let { pageNo } = this
      if (!isInit) {
        this.totalpage < pageNo || this.totalpage == pageNo ? this.pageNo = this.totalpage : this.pageNo = ++pageNo
        this.$refs.scrollView.finishLoadMore()
      }
      let res = await bqList({
        page: pageNo, size: 6, Tbname: this.Tbname, ywtype: 'LPYW'
      })
      if (pageNo > 1) {
        for (let item in res.data.data.rows) {
          this.reportList.push(res.data.data.rows[item])
          this.reportList.forEach(ele => {
            ele.stateInfo && ele.stateInfo.value && ele.stateInfo.label ? '' : ele.stateInfo = { label: '', value: '' }
            ele.ywcodeInfo && ele.ywcodeInfo.value && ele.ywcodeInfo.label ? '' : ele.ywcodeInfo = { label: '', value: '' }
          })
          this.total = res.data.data.total
          this.totalPage = res.data.data.totalpage
        }
      } else {
        this.reportList = res.data.data.rows
        this.reportList.forEach(ele => {
          ele.stateInfo && ele.stateInfo.value && ele.stateInfo.label ? '' : ele.stateInfo = { label: '', value: '' }
          ele.ywcodeInfo && ele.ywcodeInfo.value && ele.ywcodeInfo.label ? '' : ele.ywcodeInfo = { label: '', value: '' }
        })
        this.total = res.data.data.total
        this.totalPage = res.data.data.totalpage
      }
      this.isPopupShow = false
    },
    bqShow (pcnum, ywtype) { // 跳转填写信息
      this.$router.push({
        path: '/lpShow',
        query: { pcnum: pcnum, ywtype: 'LPYW' }
      })
    },
    search () {
      this.getData(this)
    },
    cleardata () {
      this.Tbname = ''
      this.pageNo = 1
      this.loading = false
      this.reportList = []
      this.totalpage = 0
      this.isSelectorShow = false
      this.getData(this)
    }
  }
}
</script>
<style lang="stylus" scoped>
.heightbom{
  height:150px;
}
/deep/ .icdoling .md-icon {
          width: 1.42rem;
          height: 1.42rem;
          line-height: 1.42rem;
      }
  .mainbody{
    background-color  #F7F6FB
    width 100vw
    height 100vh
    overflow hidden
  }
  .product{
    height 480px;
    width 960px;
    background  #fff
    border-top 20px solid #F7F6FB
    margin 0 auto
  }
  .product-lf{
    padding 35px 0
    width 100%
    height 80%
    left 30px
    position relative
  }
  .product-rg-top{
    display flex
    align-items center
    height 80px
    line-height: 0.7rem
    position relative
    border-bottom: .5px solid #eee
    justify-content: flex-end
  }
  .product-bom{
    padding 35px 0
  }
  .product-rg-bom span{
    font-family 'PingFang-SC'
    line-height 70px
    font-size 35px
    color #9D9D9D
  }
  .search{
    width 100vw
    background #fff
  }
  .search .searchlf{
    float left
    width 80%
    height 100%
  }
  .searchlf /deep/ .md-field-item-placeholder{
    font-size 0.3rem
  }
  .searchfr{
    float left
    width 10%
    height 100%
  }
  .searchfr .md-field{
    padding 0.5rem 0rem
  }
  .list{
    height 100%
  }
  .clearfix:after,.clearfix:before{
      content ""
      display table
  }
  .clearfix:after{
    clear both
  }
  .clearfix{
    *zoom 1
  }
  .xm-value{
    right 70px
  }
  .p-type{
    width 7rem
    overflow: hidden!important
    text-overflow:ellipsis!important
    white-space: nowrap!important
  }
  .lq-rq{
    right 70px
  }
  .mq-money{
    right 70px
  }
  .tb-name{
    position absolute
    left 70px
    font-size 45px
  }
  .bq-state{
    height 50px!important
    line-height 50px!important
    font-size 30px!important
    color #B88E5C
    background-color #FFF8ED
    text-align center
    padding 0px 40px
    margin-right: 20px;
  }
  .bq-ywlabel{
    height 50px!important
    line-height 50px!important
    font-size 30px!important
    color #B88E5C
    background-color #FFF8ED
    text-align center
    padding 0px 30px
    margin-right: 50px;
  }
  .tb-head{
    position absolute
    left 10px
    top 5px
    width 50px
    height 50px
  }
</style>
